import axios from "axios";
import Cookie from "universal-cookie";

export async function getTokenOrRefresh() {
    const cookie = new Cookie();
    const speechToken = cookie.get("speech-token");

    if (speechToken === undefined) {
        try {
            const res = await axios.get("/api/getToken");
            const asr_token = res.data.asr_token;
            const mt_token = res.data.mt_token;
            const region = res.data.region;
            cookie.set(
                "speech-token",
                region + ":" + asr_token + ":" + mt_token,
                {
                    maxAge: 540,
                    path: "/",
                }
            );

            return {
                asr_authToken: asr_token,
                mt_authToken: mt_token,
                region: region,
            };
        } catch (err) {
            return {
                asr_authToken: null,
                mt_authToken: null,
                error: err.response.data,
            };
        }
    } else {
        const idx = speechToken.indexOf(":");
        const idx2 = speechToken.lastIndexOf(":");
        return {
            asr_authToken: speechToken.slice(idx + 1, idx2),
            mt_authToken: speechToken.slice(idx2 + 1),
            region: speechToken.slice(0, idx),
        };
    }
}
